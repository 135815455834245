import React, { useEffect, useState } from 'react';
import AdvancedFilter from '../../components/Filter/AdvancedFilter';
import api from '../../services/api';
import NewCard from '../../components/UI/NewCard';

const TestPage = () => {
  const [filterOptions, setFilterOptions] = useState(null);

  useEffect(() => {
    console.log('Before API Call');

    api
      .get("/posts/filter")
      .then((res) => {
        console.log('API Response:', res.data);
        setFilterOptions(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        console.log('After API Call');
      });
  }, []);

  const handleChangeCity = (city) => {
    console.log('City changed:', city);
  };

  const handleSearch = (formData) => {
    console.log('Search performed with data:', formData);
  };

  return (
    <div>
      <h1>Test Page</h1>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '390px', marginRight: '20px' }}>
          {filterOptions ? (
            <AdvancedFilter
              filterOptions={filterOptions}
              onChangeCity={handleChangeCity}
              onSearch={handleSearch}
            />
          ) : (
            <p>Loading filter options...</p>
          )}
        </div>
          <NewCard
            id="1"
            post_title="Sample Property"
            src="https://i.pinimg.com/originals/af/b1/f0/afb1f085af87d541c8760723d172cd78.jpg" 
            tipo="Apartment"
            area="100"
            valor_inicial="250000"
            bairro="Sample Neighborhood"
            cidade={{ name: "Sample City" }}
            numberPhone="1234567890"
            emailProprietary="sample@example.com"
          />
      </div>
    </div>
  );
};

export default TestPage;