import React, { Component } from 'react';
import api from '../../services/api';
import Button from '../../components/UI/Button/Button';
import xml2js from "xml2js";
import configToken from "../../utils/configToken";
import { Helmet } from 'react-helmet';

class PostXml extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    submitForm = (event) => {
        event.preventDefault();
        const file = this.fileInput.current.files[0]
        const read = new FileReader();
        read.readAsText(file);
        
        read.onloadend = function(){        
            const parser = new xml2js.Parser({explicitArray : false});
            console.log(read.result)
            parser.parseString(read.result, function (err, result){
                console.log(result)
                api.post('/posts/postxml', result, configToken()).then(res => {
                    console.log(res)
                    window.alert("XML Enviado!")
                }).catch(err => {
                  window.alert("Erro ao enviar XML")
                  console.log(err)
                }) 
            })
        }

    };
      
    render() {
        return (
            <div className="WrapperDiv">
                <Helmet>
                    <title> Upload XML — Plano Cidade</title>
                </Helmet>
                <div className="formularios Form-center">
                    <p className="titulo">Upload XML</p>

                    <form onSubmit={this.submitForm}>
                        <div className="inputDiv" style={{display:"flex", justifyContent: "center", alignItems : "center"}}>
                            <input type="file" style={{padding: "30px", position: "relative"}} ref={this.fileInput} />
                        </div>
                        <Button>Enviar</Button>
                    </form>
                
                </div>
            </div>
        );
      }  
}

export default PostXml;