import React, { Component } from 'react';
import './index.scss';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FooterButton from '../../../components/UI/FooterButton/FooterButton';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1
  },
  logo: {
    width: 100,
    maxWidth: 345,
    backgroundColor: 'transparent'
  },
  media: {
    height: 140,
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  font: {
    color: 'white'
  },
  button: {
    display: 'flex'
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '300px',
      flexDirection: 'column'
    }
  }
});

class Footer extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div style={{ backgroundColor: '#56564c', padding: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <div className={classes.footer}>
          <Card className={classes.logo} elevation={0}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="planocidade"
                width="100px"
                image='http://planocidade.com.br/wp-content/themes/loteamentosbauru/assets/img/logo_rodape.png'
              />
            </CardActionArea>
          </Card>
          <ButtonGroup backgroundColor='#56564c'>
            <NavLink className={classes.button} to="/quem-somos"><FooterButton>QUEM SOMOS</FooterButton></NavLink>
            <NavLink className={classes.button} to="/parceiros"><FooterButton>PARCEIROS</FooterButton></NavLink>
            <NavLink className={classes.button} to="/contato"><FooterButton>CONTATO</FooterButton></NavLink>
          </ButtonGroup>
          <Typography className={classes.font}>TODOS OS DIREITOS RESERVADOS</Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(Footer);
