import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { NavLink } from "react-router-dom";
import ContactButtons from "../../ContactButtons/ContactButtons";

const useStyles = makeStyles({
  root: {
    display: "flex",
    maxWidth: 600,
  },
  media: {
    width: 180,
    height: 180,
    objectFit: "cover",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    paddingLeft: 10,
  },
});

const NewCard = ({
  id,
  post_title,
  src,
  tipo,
  area = undefined,
  valor_inicial = undefined,
  valor_do_condomnio = undefined,
  bairro = undefined,
  cidade = undefined,
  pathurl = "/imoveis/",
  numberPhone = undefined,
  emailProprietary,
  created_from = "",
  mdSize = 4,
  smSize = 6,
}) => {
  const classes = useStyles();

  const showIf = (param) => param && param !== "0";

  return (
    <Grid className={classes.card} item xs={12} sm={smSize} md={mdSize}>
      <Card className={classes.root}>
        <CardActionArea>
          <div style={{ display: "flex" }}>
            <CardMedia className={classes.media} image={src} />
            <div className={classes.content}>
              <NavLink to={pathurl + id} className={classes.navlink}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {post_title && post_title}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ color: "#a0c500" }}
                  >
                    {tipo && tipo.name ? tipo.name : tipo}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {showIf(valor_inicial) && (
                      <span style={{ fontSize: "12px" }}>
                        {"Preço: R$ " + valor_inicial}
                        <br />
                      </span>
                    )}
                    {showIf(valor_do_condomnio) && (
                      <span style={{ fontSize: "12px" }}>
                        {"Condomínio: R$ " + valor_do_condomnio}
                        <br />
                      </span>
                    )}
                    {showIf(area) && (
                      <span style={{ fontSize: "12px" }}>{area + " m²"}</span>
                    )}
                    {showIf(valor_inicial) ||
                    showIf(valor_do_condomnio) ||
                    showIf(area) ? (
                      <br />
                    ) : null}
                    <span style={{ fontSize: "12px" }}>
                      {bairro && bairro}
                    </span>
                    <br />
                    <span style={{ fontSize: "12px" }}>
                      {cidade && cidade.name && cidade.name}
                    </span>
                    <br />
                  </Typography>
                </CardContent>
                {created_from === "xml" && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "7px",
                      justifyContent: "center",
                    }}
                  >
                    <ContactButtons
                      numberPhone={numberPhone}
                      emailProprietary={emailProprietary}
                    />
                  </div>
                )}
              </NavLink>
            </div>
          </div>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default NewCard;