import React from 'react';
import HeaderNav from "../../UI/HeaderNav";
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  root2: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  paper: {
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  title: {
    fontSize: '20px',
    [theme.breakpoints.up(720)]: {
      fontSize: '36px'
    },
  }
}));

export default function Search ({ tipo, cidade, lazer, itensDeLazer, itensDeEmpreendimentos, elevadores, handleChange, find }) {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title> Venal — Plano Cidade</title>
      </Helmet>
      <HeaderNav padd={{"padding-top": "32px", "paddingBottom": "32px", "marginLeft": "8px", "marginRight": "8px" }} atSearch>
        <p className={classes.title} style={{ color: '#aac401', fontWeight: '400' }}>EMPREENDIMENTOS IMOBILIÁRIOS</p>
        <br />
        <Card className="Card" style={{ maxWidth: '800px', margin: 'auto', padding:'16px', borderRadius: '8px' }} >
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel>Cidade</InputLabel>
                  <Select
                    native
                    value={cidade}
                    onChange={handleChange}
                    label="Cidade"
                    inputProps={{
                      name: 'cidade',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={'Bauru'}>Bauru</option>
                    <option value={'Piratininga'}>Piratininga</option>
                    <option value={'Agudos'}>Agudos</option>
                    <option value={'Pederneiras'}>Pederneiras</option>
                    <option value={'Lençóis Paulista'}>Lençóis Paulista</option>
                    <option value={'Londrina'}>Londrina</option>
                    <option value={'Jaú'}>Jaú</option>
                    <option value={'Santos'}>Santos</option>
                    <option value={'Praia Grande'}>Praia Grande</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel htmlFor="outlined-age-native-simple">Empreendimento</InputLabel>
                  <Select
                    value={tipo}
                    onChange={handleChange}
                    label="Empreendimento"
                    inputProps={{
                      name: 'tipo',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    {itensDeEmpreendimentos.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel htmlFor="outlined-age-native-simple">Itens de Lazer</InputLabel>
                  <Select
                    multiple
                    value={lazer}
                    onChange={handleChange}
                    label="Itens de Lazer"
                    inputProps={{
                      name: 'lazer',
                      id: 'outlined-age-native-simple',
                    }}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    {itensDeLazer.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={lazer.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>)
                    )}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={4}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel htmlFor="outlined-age-native-simple">Elevadores</InputLabel>
                  <Select
                    native
                    value={elevadores}
                    onChange={handleChange}
                    label="Número de elevadores"
                    inputProps={{
                      name: 'elevadores',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={1}>1+</option>
                    <option value={2}>2+</option>
                    <option value={3}>3+</option>
                  </Select>
                </FormControl>
              </Grid> */}
            </Grid>
          </div>
        </Card>
        <br />
        <div style={{display: 'flex', justifyContent: 'center', padding:'8px' }} >
          <button onClick={find} className="FindButton" variant="contained" >
            BUSCAR IMÓVEIS
          </button>
        </div>
      </HeaderNav>
    </>
  )
}
