import React, { Component } from 'react';
import DefaultPage from '../Busca/Venal';

class Home extends Component {
  render() {
    return (
      <>
        <DefaultPage />
      </>
    )
  }
}

export default Home;
