import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  generalSelection: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    maxWidth: '720px',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}));

export default function GeneralSelect ({ value, onChange, nameValue, updateWidth = '100%', optionsArray}) {
  const classes = useStyles();
  const nameLabel = nameValue.charAt(0).toUpperCase() + nameValue.slice(1);

  return (
    <div className={classes.generalSelection}>
      <Box sx={{ minWidth: 120 }}>
        <FormControl size='small' variant="outlined" style={{ width: updateWidth}}>
          <InputLabel>{nameLabel}</InputLabel>
          <Select
            required={(nameValue === "cidade")}
            native
            value={value}
            onChange={onChange}
            label={nameLabel}
            inputProps={{
              name: {nameValue},
              id: 'outlined-age-native-simple',
            }}
          >
            <option aria-label="None" value="" />
            {optionsArray && optionsArray.map(el => (<option value={el}>{el}</option>))}
          </Select>
        </FormControl>
      </Box>
    </div>
  )
}