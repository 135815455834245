import React, { Component } from 'react';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';

import Partners from "../../components/Partners/Partners";
import SearchPosts from "../../components/SearchPosts/SearchPosts";
import CitySelect from "../../components/CitySelect/CitySelect";

import * as actions from "../../store/actions/index";
import api from "../../services/api";

const useStyles = (_theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  partnerTitle: {
    display: 'flex',
    justifyContent: 'center',
    color: '#aac401',
    fontSize: '30px'
  }
});

class Builders extends Component {
  state = {
    value: '',
    post_title: '',
    count: undefined,
    show: false,
    options: [],
    page: 1,
    showList: false,
    city: window.localStorage.getItem('city') ? window.localStorage.getItem('city') : null,
    postsSelected: []
  }

  queryToPosts = (postQuery) => {
    const cityItem = window.localStorage.getItem('city')
    if (cityItem) postQuery.city = cityItem
    this.props.onFetchPosts(postQuery);
    this.setState({
      count: undefined
    })
  }

  componentDidMount = () => {
    var pageId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    this.props.onFetchAdvertisements('/parceiros/' + pageId);

    const postQuery = {
      adminUrl: '',
      postType: this.props.partner
    };

    if (this.props.partner) {
      this.queryToPosts(postQuery);
    } else {
      this.props.onFetchPosts({
        adminUrl: '',
        postType: 'empreendimento',
        partner: pageId
      });
    }
  }

  onInputChange = async (e) => {
    let options = []
    if (e !== this.state.value && e && typeof e === 'string') {
      await api.get('/posts/autocomplete?post_title=' + e + '&post_type=' + this.props.partner)
      .then(res => {
        for (const element of res.data) {
          options.push({value: element, label: element.post_title})
        }
        this.setState({show: true, options: options, value: e, showList: false})
      })
      .catch(err => {
        console.log(err)
      })
    }

    return options
  }

  onInputSubmit = async (e, page = 1, allowEmpty = false) => {
    let options = []
    this.setState({show: false, options: []})
    if ((e && typeof e === 'string') || allowEmpty) {
      await api.get('/posts/search?post_title=' + e + '&post_type=' + this.props.partner + '&page=' + page)
      .then(res => {
        for (const element of res.data.posts) {
          options.push({value: element, label: element.post_title})
        }

        if (options.length === 0) this.setState({showList: false})
        else this.setState({showList: true})

        this.setState({show: true, postsSelected: res.data.posts, count: res.data.count, value: e, post_title: e, showList: true})
      })
      .catch(err => {
        console.log(err)
      })
    }

    return options
  }

  promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.state.options);
      }, 10000);
  });

  getTitle = (props) => props ? (
    (props.posts.partner && props.posts.partner.post_title && props.posts.partner.post_title)
    || (props.partnerLabel && props.partnerLabel)
  ) : 'Parceiros'

  onChange = (e) => {
    const city = e.target.value;
    if (city) window.localStorage.setItem('city', city);
    else window.localStorage.setItem('city', '');

    this.setState({
      city: city,
      page: 1,
      postsSelected: []
    });

    const postQuery = {
      adminUrl: '',
      postType: this.props.partner
    };

    this.queryToPosts(postQuery);
  }

  handlePageChange = (event, page) => {
    this.setState({ page: page });
    this.onInputSubmit(this.state.post_title, page, true)
  };

  render() {
    const search = <SearchPosts
      loadDefault={() => this.onInputSubmit("", 1, true)}
      itsEmpty={() => {}}
      selected={this.state.posts}
      clickAuto={this.onInputChange}
      clickSubmit={this.onInputSubmit}
      onPlaceSelected={this.onInputSubmit}
      load={this.promiseOptions}
    />

    const citySearch = (
      <CitySelect city={this.state.city} onChange={this.onChange} />
    );

    return (
      this.props.posts && <Partners
        search={search}
        citySearch={citySearch}
        title={this.getTitle(this.props)}
        posts={this.props.posts.posts}
        pathurl={this.props.pathurl}
        partnerUrl={this.props.posts && this.props.posts.partner && this.props.posts.partner.metas && this.props.posts.partner.metas.site}
        advertisements={this.props.advertisements}
        postSelected={this.state.postsSelected}
        count={this.state.count === undefined ? this.props.posts.count : this.state.count}
        page={this.state.page}
        handlePageChange={this.handlePageChange}
        pagination
    />
    )
  }
}

const mapStateToProps = state => {
  return {
    advertisements: state.advertise.advertisements,
    posts: state.imoveis.posts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchPosts: (payload) => dispatch(actions.fetchPosts(payload)),
    onFetchAdvertisements: (page) => dispatch(actions.fetchAdvertisements(page))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(Builders));
