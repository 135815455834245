import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Helmet } from 'react-helmet';

import FindButton from '../../components/UI/FindButton/FindButton';
import Footer from "../../components/UI/Footer";
import * as actions from '../../store/actions/index';
import api from "../../services/api";
import configToken from "../../utils/configToken";

class Contato extends Component {
    state = {
        reason: '',
        page: '',
        content: '',
        load: false
    }

    componentDidMount() {
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState({loading: true})
        const userdata = {
            reason: this.state.reason,
            page: this.state.page,
            content: this.state.content
        }
        console.log('userdata',userdata)
        api.post('/contact', userdata, configToken())
            .then(res => {
                alert('Mensagem enviado com sucesso')
                this.setState({loading: false, reason: '', page: '', content: ''})
            })
            .catch(err => {
            })

    }

    render() {
        return (
            <>
            <Helmet>
                <title> Sugestões — Plano Cidade</title>
            </Helmet>
            <div style={{ backgroundColor: '#ffffff', padding: '30px' }}>
                <div>
                    <br/>
                    <br/>
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#687700', fontSize: '30px' }}>Entre em contato com o Plano Cidade!</span><br /><br />
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#57574d', fontSize: '20px' }}>Somos um portal que disponibiliza tudo sobre os empreendimentos imobiliários nas cidades onde estamos.</span><br /><br />
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#57574d', fontSize: '20px' }}>Tem alguma sugestão? Quer corrigir alguma informação? Deseja que o Plano Cidade esteja em sua cidade?</span><br /><br />
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#57574d', fontSize: '20px' }}>Fale conosco através do formulário abaixo.</span><br /><br />
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Seu Nome<span style={{ color: 'red' }}>*</span>:</b></span>
                    <input style={{ width: '500px', height: '34px' }} value={this.state.name} onChange={this.handleChangeName}></input><br /><br />
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Seu Email<span style={{ color: 'red' }}>*</span>:</b></span>
                    <input style={{ width: '500px', height: '34px' }} value={this.state.email} onChange={this.handleChangeEmail}></input><br /><br />
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Seu Celular:</b></span>
                    <input style={{ width: '500px', height: '34px' }} value={this.state.cellPhoneNumber} onChange={this.handleChangeCellPhoneNumber}></input><br /><br />
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Assunto:</b></span>
                    <input style={{ width: '500px', height: '34px' }} value={this.state.cellPhoneNumber} onChange={this.handleChangeCellPhoneNumber}></input><br /><br />
                    <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Mensagem<span style={{ color: 'red' }}>*</span>:</b></span>
                    <textarea rows={10} style={{ width: '500px' }} value={this.state.mesage} onChange={this.handleChangeMesage}></textarea><br /><br />
                    <FindButton>Enviar</FindButton>
                    {/* <p className="titulo">Entre em contato com o Plano Cidade!</p>
                    <p className="subtitulo">Somos um portal que disponibiliza tudo sobre os empreendimentos imobiliários nas cidades onde estamos.</p> */}
                    {/* <form onSubmit={this.submitHandler}>
                        {load}
                        {!loading && <div> <Select
                            required
                            name={'reason'}
                            isSearchable
                            placeholder={'Escolha um motivo'}
                            options={[
                                {value: 'Ajuste', label: 'Ajuste'},
                                {value: 'Sugestão', label: 'Sugestão'}
                            ]}
                            onChange={e => this.setState({reason: e.value})}
                        />
                        <br/>
                        <Select
                            required
                            name={'page'}
                            isSearchable
                            placeholder={'Escolha um item'}
                            options={[
                        {value: 'Home', label: 'Home'},
                        {value: 'Meus Imóveis (mapa)', label: 'Meus Imóveis (mapa)'},
                        {value: 'Meus Imóveis (lista)', label: 'Meus Imóveis (lista)'},
                        {value: 'Buscar Imóveis (mapa)', label: 'Buscar Imóveis (mapa)'},
                        {value: 'Buscar Imóveis (lista)', label: 'Buscar Imóveis (lista)'},
                        {value: 'Meus Clientes', label: 'Meus Clientes'},
                        {value: 'Editar Imóveis', label: 'Editar Imóveis'},
                        {value: 'PDF Imóveis', label: 'PDF Imóveis'},
                        {value: 'Localização', label: 'Localização'},
                        {value: 'Outros', label: 'Outros'}
                            ]}
                            onChange={e => this.setState({page: e.value})}
                            />
                            <br/>
                            <textarea name={'content'} value={content} className={'form-control'} placeholder={'Mensagem'} onChange={e => this.setState({content: e.target.value})}/>
                            <br/>
                            <Button>Enviar</Button></div>}

                    </form> */}

                </div>
            </div>
            <br />
            <Footer />
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        messagedone: state.auth.messagedone,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignup: (formdata) => dispatch(actions.signup(formdata))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contato);
