import React from 'react';
import {NavLink} from 'react-router-dom';

import './NavigationItem.scss';

const updateLinkActive = function (props) {
    let url = window.location.pathname

    if (url.indexOf('busca') !== -1 && props.children === 'Buscar Imóveis') {
        return true
    } else if (url.indexOf('meusimoveis') !== -1 && props.children === 'Meus Imóveis') {
        return true
    } else if (url.indexOf('meusclientes') !== -1 && props.children === 'Clientes') {
        return true
    } else if (url.indexOf('assinatura') !== -1 && props.children === 'Assinatura') {
        return true
    } else if (props.clicked) {
        return true
    } else return url.indexOf('meusdados') !== -1 && props.children === 'Meus dados';
}

const navigationItem = (props) => (
    <li className="NavigationItem">
        <NavLink
            to={props.link}
            exact={props.exact}
            isActive={() => updateLinkActive(props)}
            className={props.className}
            onClick={props.click}
            activeClassName="active">{props.children}</NavLink>
    </li>
);

export default navigationItem;
