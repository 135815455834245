import React, {Component} from 'react';
import onClickOutside from "react-onclickoutside";
import NavigationItem from './NavigationItem/NavigationItem';

import './NavigationItems.scss';
import iconUser from '../../../assets/images/icon-user-white.svg';

class navigationItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visibleVenal: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleMenuVenal = this.toggleMenuVenal.bind(this);
  }

  toggleMenu() {
    this.setState({ visible: !this.state.visible })
  }

  toggleMenuVenal() {
    this.setState({ visibleVenal: !this.state.visibleVenal })
  }

  handleClickOutside = () => {
    this.setState({
      visible: false,
      visibleVenal: false
    })
  }

  render() {
    return (
      <ul className={ this.props.isAuthenticated ? "NavigationItems" : "NavigationItems MenuDisconnected" }>
        {
          this.props.isAuthenticated && (
            <>
              <li className="toggle-button NavigationItem">
                <NavigationItem className="menu-fx MenuDesktop" link={window.location.pathname} click={ this.toggleMenuVenal } clicked={ this.state.visibleVenal }>Valor Venal</NavigationItem>

                <ul className="MenuMobile">
                  <NavigationItem link="/busca/venal">Buscar Imóveis</NavigationItem>
                  <NavigationItem link="/meusimoveis/lista">Meus Imóveis</NavigationItem>
                  <NavigationItem link="/meusclientes">Clientes</NavigationItem>
                </ul>

                {
                  this.state.visibleVenal && (
                    <Menu>
                      <NavigationItem link="/busca/venal">Buscar Imóveis</NavigationItem>
                      <NavigationItem link="/meusimoveis/lista">Meus Imóveis</NavigationItem>
                      <NavigationItem link="/meusclientes">Clientes</NavigationItem>
                      <NavigationItem link="/postxml">Upload XML</NavigationItem>
                    </Menu>
                  )
                }
              </li>


              {(this.props.role === 'Master' || this.props.role === 'Admin') && <NavigationItem className="menu-fx" link="/admin/panel">Admin</NavigationItem>}

              <li className="toggle-button NavigationItem">
                <button className="buttton-icon-user" onClick={ this.toggleMenu }>
                  <img alt="Menu" className="icon-user" src={iconUser} />
                </button>

                <ul className="MenuMobile">
                  <NavigationItem link="/meusdados">Meus Dados</NavigationItem>
                  <NavigationItem link="/contato">Sugestões</NavigationItem>
                  <NavigationItem link="/logout">Sair</NavigationItem>
                </ul>

                {
                  this.state.visible && (
                    <Menu>
                      <NavigationItem link="/meusdados">Meus Dados</NavigationItem>
                      <NavigationItem link="/contato">Sugestões</NavigationItem>
                      <NavigationItem link="/logout">Sair</NavigationItem>
                    </Menu>
                  )
                }
              </li>
            </>
          )
        }
        {
          !this.props.isAuthenticated && (
            <>
              <NavigationItem className="menu-fx" link="/busca/imoveis">Valor Venal</NavigationItem>
              <NavigationItem className="menu-fx" link="/lancamentos">Lançamentos</NavigationItem>
              {/* <NavigationItem className="menu-fx" link="/imoveis-a-venda">Imóveis à venda</NavigationItem> */}
              <NavigationItem className="menu-fx" link="/parceiros">Parceiros</NavigationItem>
              <NavigationItem className="menu-fx" link="/anuncie-conosco">Anuncie Conosco</NavigationItem>
              <NavigationItem className="menu-fx" link="/auth">Login</NavigationItem>
              {/* <li className="Buttonsignup margin-left"><NavLink to="/signup"><Button btnType="ghost">Cadastre-se</Button></NavLink></li> */}
            </>
          )
        }
      </ul>
    )
  }
}

class Menu extends React.Component {
  render() {
    return (
      <div className="toggleMenu">
        <ul>
          { this.props.children }
        </ul>
      </div>
    )
  }
}

// TODO: Fazer o componente do MenuItem
// class MenuItem extends React.Component {
//   render() {
//     return (
//       <NavigationItem link="/">{this.props.children}</NavigationItem>
//     )
//   }
// }

export default onClickOutside(navigationItems);
