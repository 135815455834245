import React, { Component } from 'react';
import FindButton from '../../components/UI/FindButton/FindButton';
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../../components/UI/Footer";


class Release extends Component {

  classes = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    root2: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    paper: {
      textAlign: "center",
      color: theme.palette.text.secondary
    }
  }));

  state = {
    showInfoWindow: [],
    showInfoWindowUser: true,
    showWindowGroup: false,
    myCoordinates: {
      latitude: sessionStorage.getItem('latitude') !== undefined ? Number(sessionStorage.getItem('latitude')) : 0,
      longitude: sessionStorage.getItem('longitude') !== undefined ? Number(sessionStorage.getItem('longitude')) : 0
    },
    favoritou: false,
    address: '',
    loadingAddress: false,
    position: null,
    id: null,
    imovel: null,
    quadrinha: null,
    mostrarPainel: false,
    mostrarQuadrinha: false,
    loadingFavorito: false,
    scroll: false,
    city: '',
    type: '',
    elevator: '',
    lazer: '',
    find: false,
    name: '',
    email: '',
    cellPhoneNumber: '',
    mesage: ''
  };

  handleChangeCity = (event) => {
    this.setState({
      city: event.target.value,
    });
  }

  handleChangeType = (event) => {
    this.setState({
      type: event.target.value,
    });
  }

  handleChangeName = (event) => {
    this.setState({
      name: event.target.value,
    });
  }

  handleChangeEmail = (event) => {
    this.setState({
      email: event.target.value,
    });
  }

  handleChangeCellPhoneNumber = (event) => {
    this.setState({
      cellPhoneNumber: event.target.value,
    });
  }

  handleChangeMesage = (event) => {
    this.setState({
      mesage: event.target.value,
    });
  }

  render() {
    return (
      <>
        {/* <br />
        <div style={{ width: '728px', height: '90px', borderStyle: 'solid', borderColor: '#dcdcdc', margin: 'auto',  }}>anúncio</div>
        <br /> */}
        {/* <div style={{ backgroundColor: '#ededed', padding: '30px' }}> */}
        <div style={{ backgroundColor: '#ffffff', display: 'flex', justifyContent: 'center', padding: '30px' }}>
          {/* <span style={{ display: 'flex', justifyContent: 'center', color: '#687700', fontSize: '30px' }}>Anuncie Conosco</span>
          <br /><br /> */}
          {/* <span style={{ display: 'flex', justifyContent: 'center', color: '#aac401', fontSize: '30px' }}>Lançamentos</span><br /> */}
          <div className={this.classes.root} style={{ width: '1170px' }}>
            <div>
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#687700', fontSize: '30px' }}>Entre em contato com o Plano Cidade!</span><br /><br />
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#57574d', fontSize: '20px' }}>Somos um portal que disponibiliza tudo sobre os empreendimentos imobiliários nas cidades onde estamos.</span><br /><br />
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#57574d', fontSize: '20px' }}>Tem alguma sugestão? Quer corrigir alguma informação? Deseja que o Plano Cidade esteja em sua cidade?</span><br /><br />
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#57574d', fontSize: '20px' }}>Fale conosco através do formulário abaixo.</span><br /><br />
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Seu Nome<span style={{ color: 'red' }}>*</span>:</b></span>
              <input style={{ width: '500px', height: '34px' }} value={this.state.name} onChange={this.handleChangeName}></input><br /><br />
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Seu Email<span style={{ color: 'red' }}>*</span>:</b></span>
              <input style={{ width: '500px', height: '34px' }} value={this.state.email} onChange={this.handleChangeEmail}></input><br /><br />
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Seu Celular:</b></span>
              <input style={{ width: '500px', height: '34px' }} value={this.state.cellPhoneNumber} onChange={this.handleChangeCellPhoneNumber}></input><br /><br />
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Assunto:</b></span>
              <input style={{ width: '500px', height: '34px' }} value={this.state.cellPhoneNumber} onChange={this.handleChangeCellPhoneNumber}></input><br /><br />
              <span style={{ display: 'flex', justifyContent: 'flex-start', color: '#000000', fontSize: '16px' }}><b>Mensagem<span style={{ color: 'red' }}>*</span>:</b></span>
              <textarea rows={10} style={{ width: '500px' }} value={this.state.mesage} onChange={this.handleChangeMesage}></textarea><br /><br />             
              <FindButton>Enviar</FindButton><br /><br />
            </div>
          </div>
        </div>
        {/* <br />
        <div style={{ width: '728px', height: '300px', borderStyle: 'solid', borderColor: '#dcdcdc', margin: 'auto' }}>anúncio</div>
        <br /> */}
        <Footer />
      </>
    )
  }
}

export default Release;
