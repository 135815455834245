import React, { Component } from 'react';
import { Carousel } from "react-responsive-carousel";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Helmet } from 'react-helmet';
import Footer from "../../components/UI/Footer";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import HeaderNav from '../../components/UI/HeaderNav';
import api from "../../services/api";

import './index.scss';
import { postMetasFieldSchemas } from '../../utils/fields';
import { serveImage } from '../../utils/serveImage';
import ContactButtons from '../../components/ContactButtons/ContactButtons';


const useStyles = (theme) => ({
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  },
  table: {
    maxWidth: 700
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  columns: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  folders: {
    display: 'flex'
  }
});

class Information extends Component {
  state = {
    rows: [],
    post: [],
    fotos: [],
    planta: [],
    bairro: '',
    estado: '',
    cidade: undefined,
    tipo: undefined,
    position: undefined,
    map_type: undefined,
    zoom: undefined,
    banner_topo: undefined,
    banner_lateral: undefined
  }

  componentDidMount() {
    var pageId = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    api.get(`/posts/post?id=` + pageId).then(res => {
      console.log(res.data);
      this.setState({...this.state, 
        created_from: res.data.created_from !== undefined ? res.data.created_from : '',
        post_title: res.data.post_title,
        descricao : res.data.descricao,
        thumbnail: res.data.metas.thumbnail,
        banner_topo: res.data.metas.banner_topo,
        banner_principal: res.data.metas.banner_principal,
        banner_lateral: res.data.metas.banner_lateral,
        fotos: res.data.metas.fotos,
        planta: res.data.metas.planta,
        area_lote: res.data.metas.area_lote,
        area_construida: res.data.metas.area_construida,
        area_loteamento: res.data.metas.area_loteamento,
        arquivos: res.data.metas.arquivos,
        cep: res.data.metas.cep,
        logradouro: res.data.metas.logradouro,
        nmero: res.data.metas.nmero,
        cidade: res.data.metas.cidade,
        complemento: res.data.metas.complemento,
        bairro: res.data.metas.bairro,
        data_de_atualizao_do_valor_do_condomnio: res.data.metas.data_de_atualizao_do_valor_do_condomnio,
        estado: res.data.metas.estado,
        ibge: res.data.metas.ibge,
        site: res.data.metas.site,
        total_andares: res.data.metas.total_andares,
        total_de_lotes: res.data.metas.total_de_lotes,
        total_imoveis: res.data.metas.total_imoveis,
        vagas_garagem: res.data.metas.vagas_garagem,
        tipo: res.data.metas.tipo,
        valor_do_condomnio: res.data.metas.valor_do_condomnio,
        valor_inicial: res.data.metas.valor_inicial,
        areas_de_lazer: res.data.metas.areas_de_lazer,
        _pronamic_google_maps_address: res.data.metas._pronamic_google_maps_address,
        position: {
          lat: res.data.metas.location_coordinates[1] && parseFloat(res.data.metas.location_coordinates[1]),
          lng: res.data.metas.location_coordinates[0] && parseFloat(res.data.metas.location_coordinates[0])
        },
        zoom: res.data.metas._pronamic_google_maps_zoom && parseInt(res.data.metas._pronamic_google_maps_zoom),
        construtora: res.data.metas.construtora,
        administradora_condominio: res.data.metas.administradora_condominio,
        prestadora_de_servico: res.data.metas.prestadora_de_servico,
        imobiliaria: res.data.metas.imobiliaria,
        corretora_de_seguro: res.data.metas.corretora_de_seguro,
        data_de_entrega: res.data.metas.data_de_entrega,
        total_elevadores: res.data.metas.total_elevadores,
        total_torres: res.data.metas.total_torres,
        total_blocos: res.data.metas.total_blocos,
        pmb_sequencia: res.data.metas.pmb_sequencia,
        email_do_contato: res.data.metas.email_do_contato,
        celular_de_contato: res.data.metas.celular_de_contato,
        quantidade_dormitorios: res.data.metas.quantidade_dormitorios,
        quantidade_banheiros: res.data.metas.quantidade_banheiros,
        vagas_de_garagem: res.data.metas.vagas_de_garagem,
        quantidade_suites: res.data.metas.quantidade_suites,
        quantidade_salas: res.data.metas.quantidade_salas,
        area: res.data.metas.area,
        padroes_de_unidade: res.data.metas.padroes_de_unidade
      });
    })
    .catch(err => {
      console.log(err)
    })
  }

  createData (name, description) {
    return { name, description };
  }

  render() {
    const { classes } = this.props;

    // const buildBanner = (banner, width, height) => (
    //   <div style={{ display: 'flex', justifyContent: 'center', margin: '8px' }}>
    //     {
    //       banner
    //       && banner.url
    //       && banner.link
    //       && <a href={banner.link} target="_blank" rel="noopener noreferrer"><img alt={banner.link} src={serveImage(banner.url)} style={{ maxWidth: width, maxHeight: height, width: '100%' }} /></a>
    //     }
    //   </div>
    // )

    // const buildListBanner = (banners, width, height) => banners && banners.length > 0 && banners.map(banner => buildBanner(banner, width, height));

    const imagesToCarousel = (title, images) => (
      <div>
        <h3 id='fotos' class='green-title' style={{ color:'#a0c500' }}>{title}</h3>
        <div style={{ display: 'flex', textAlign: 'flex-start', width: '100%', maxWidth: '700px' }}>
          <Carousel dynamicHeight={true} showIndicators={false} showStatus={false} autoPlay={true}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={serveImage(image.url)} alt="Foto" />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    )
    
    const formatValueToCell = (value, join = false, isUrl = false) => {
      if (Array.isArray(value) && join) {
        return value.map(v => v.name || v).join(', ')
      }

      if (Array.isArray(value) && isUrl) {
        return value.map((v, idx) => <a href={v.url} target="_blank" rel="noopener noreferrer">Arquivo {idx + 1}; </a>)
      }

      if (value.post_type === 'construtora' || value.post_type === 'administradora' || value.post_type === 'imobiliaria'){
        return <a href={'/parceiros/imoveis/'+ value.slug }>{value.name}</a>
      } else {
        return value.name || value
      }
    }

    const buildTableRow = (key, isPadroesUnidade = false, index = null) => {
      let keyValue = this.state[key];
      if (isPadroesUnidade){
        keyValue = this.state.padroes_de_unidade[index][key];
      }

      let join = false;
      let isUrl = false;
      if (key === "areas_de_lazer") join = true;
      if (key === "arquivos") isUrl = true;

      const cellValue = keyValue && formatValueToCell(keyValue, join, isUrl);

      return (
        cellValue && cellValue !== "0" && postMetasFieldSchemas[key] ?
        <TableRow className={classes.tableRow} key={key}>
          <TableCell width='170px' component="th" scope="row">
            <b>{postMetasFieldSchemas[key].label}</b>
          </TableCell>
          <TableCell align="left">
            {cellValue}
          </TableCell>
        </TableRow> : ""
      )
    }

    return (
      <div>
        <Helmet>
          <title> {this.state.post_title ? this.state.post_title : ""} — Plano Cidade</title>
        </Helmet>
        {/* {
          buildBanner(this.state.banner_topo, '728px', '90px')
        } */}
        <HeaderNav height={'30vw'} padd={{"padding-top": "50px", "paddingBottom": "50px", "marginLeft": "8px", "marginRight": "8px" }} imageUrl={(this.state.thumbnail && this.state.thumbnail.url) || (this.state.fotos && this.state.fotos.length > 0 && this.state.fotos[0].url) || (this.state.banner_principal && this.state.banner_principal.url)} initialValue={this.state.valor_inicial} />
        {console.log(this.state)}
        <div className={classes.menu}>
          {this.state.fotos.length > 0 && <a href="#fotos"><Button size='large'><span style={{ fontSize: '20px', color: '#57574d' }}>FOTOS</span></Button></a>}
          {this.state.planta.length > 0 && <a href="#plantas"><Button size='large'><span style={{ fontSize: '20px', color: '#57574d' }}>PLANTAS</span></Button></a>}
          {this.state.position && this.state.zoom && <a href="#localizacao"><Button size='large'><span style={{ fontSize: '20px', color: '#57574d' }}>LOCALIZAÇÃO</span></Button></a>}
          <a href="#ficha-tecnica"><Button size='large'><span style={{ fontSize: '20px', color: '#57574d' }}>
            {this.state.created_from === "xml" ? "CARACTERÍSTICAS" : "FICHA TÉCNICA"}
          </span></Button></a>
        </div>
        <Divider />
        <br />
        <Grid container className={classes.columns}>
          <Grid item xs={12} md={6}>
            <div className={classes.folders}>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px', marginRight: '8px' }}>
                <Typography gutterBottom variant="h5" component="h2">
                  {this.state.post_title}
                </Typography>
                <Typography gutterBottom variant="h5" component="h2" style={{ color: '#a0c500' }}>
                  {this.state.tipo && this.state.tipo.name}
                </Typography>
                {this.state.descricao && (
                  <Typography variant="body2" color="textSecondary" component="p">
                    <span style={{ fontSize: '16px' }}>{this.state.descricao}</span><br /><br />
                  </Typography>
                )}
                <Typography variant="body2" color="textSecondary" component="p">
                  <span style={{ fontSize: '12px' }}>{this.state.bairro}</span><br />
                  <span style={{ fontSize: '12px' }}>{this.state.cidade && this.state.cidade.name}{this.state.estado && this.state.estado !== "" && "/" + this.state.estado}</span><br />
                </Typography>
                {
                  this.state.created_from === 'xml' ?
                  <div style={{marginTop: '7px'}}>
                    <ContactButtons numberPhone={this.state.celular_de_contato} emailProprietary={this.state.email_do_contato} />
                  </div> : ''
                }
                {this.state.fotos.length > 0 && imagesToCarousel('Fotos', this.state.fotos)}
                {this.state.planta.length > 0 && imagesToCarousel('Plantas', this.state.planta)}
                <br />
                {/* {
                  buildBanner(this.state.banner_meio, '728px', '90px')
                } */}
                {this.state.position && this.state.zoom && (
                  <div>
                    <h3 id='localizacao' class='green-title' style={{ color:'#a0c500' }}>Localização</h3>
                    <div style={{ height: '583px' }}>
                      <Map
                        containerStyle={{ height: '583px', maxWidth: '700px', width: '96%' }}
                        google={this.props.google}
                        initialCenter={this.state.position}
                        zoom={this.state.zoom}
                      >
                        <Marker name={'Current location'} position={this.state.position} />
                      </Map>
                    </div>
                  </div>
                )}
                <br />
                <h3 id='ficha-tecnica' class='green-title' style={{ color:'#a0c500' }}>{this.state.created_from === "xml" ? "Características" : "Ficha Técnica"}</h3>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableBody>
                      {buildTableRow('tipo')}
                      {buildTableRow('_pronamic_google_maps_address')}
                      {buildTableRow('construtora')}
                      {buildTableRow('administradora_condominio')}
                      {buildTableRow('imobiliaria')}
                      {buildTableRow('total_andares')}
                      {buildTableRow('total_imoveis')}
                      {buildTableRow('total_blocos')}
                      {buildTableRow('total_torres')}
                      {buildTableRow('total_elevadores')}
                      {buildTableRow('total_de_lotes')}
                      {buildTableRow('areas_de_lazer')}
                      {buildTableRow('data_de_entrega')}
                      {buildTableRow('site')}
                      {buildTableRow('pmb_sequencia')}
                      {buildTableRow('area_lote')}
                      {buildTableRow('area_construida')}
                      {buildTableRow('area_loteamento')}
                      {buildTableRow('arquivos')}
                      {buildTableRow('vagas_garagem')}
                      {buildTableRow('valor_do_condomnio')}
                      {buildTableRow('data_de_atualizao_do_valor_do_condomnio')}
                      {buildTableRow('quantidade_dormitorios')}
                      {buildTableRow('quantidade_banheiros')}
                      {buildTableRow('vagas_de_garagem')}
                      {buildTableRow('quantidade_suites')}
                      {buildTableRow('quantidade_salas')}
                      {buildTableRow('valor_inicial')}
                      {buildTableRow('area')}
                    </TableBody>
                  </Table>
                </TableContainer>

                {Array.isArray(this.state.padroes_de_unidade) 
                  && this.state.padroes_de_unidade.length > 0 
                  && this.state.padroes_de_unidade.map((tipo, index) => (
                    <div>
                      <br />
                      <h3 id='ficha-tecnica' class='green-title' style={{ color:'#a0c500' }}>{`Tipo ${index + 1}`}</h3>
                      <TableContainer component={Paper}>
                      <Table className={classes.table} aria-label="customized table">
                        <TableBody>
                          { Object.keys(tipo).map(key => buildTableRow(key, true, index))}
                        </TableBody>
                      </Table>
                      </TableContainer>
                    </div>
                  ))
                }
                <br />
                {/* {
                  buildBanner(this.state.banner_meio, '720px', '300px')
                } */}
                <br />
              </div>
            </div>

          </Grid>
          <Grid item xs={12} md={2}>
            {/* {
              buildListBanner(this.state.banner_lateral, '160px', '600px')
            } */}
          </Grid>
        </Grid>
        <Footer />
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyCRrwsJQfUXOsVKnI3rg3cWe5bRjWXaEIA")
})(withStyles(useStyles)(Information));
