import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import * as actions from "../../store/actions/index";

import Partners from "../../components/Partners/Partners";
//import SearchPosts from "../../components/SearchPosts/SearchPosts";
import FilterSearch from "../../components/FilterSearch/FilterSearch";
import BuscaMapaImoveis from "../../containers/Busca/MapaImoveis";
import { FormControlLabel, Switch } from "@material-ui/core";

import api from "../../services/api";

const markerColor = {
  "Edifícios Comerciais": "#dcb0f7",
  "Edifícios Residenciais": "#e9faa5",
  "Loteamentos Comerciais / Industriais": "#d1d1c7",
  "Loteamentos Fechados": "#fab0aa",
};

class ImoveisAVenda extends Component {
  state = {
    mapVision: false,
    value: "",
    show: false,
    count: 1,
    page: 1,
    options: [],
    showList: false,
    postsSelected: [],
    forSale: [],
    city: null,
    neighborhood: null,
    filterOptions: null,
    filterData: null,
    specificPartners:
      window.location.pathname.includes("construtora") ||
      window.location.pathname.includes("administradora"),
  };

  componentDidMount = () => {
    let cityItem = window.localStorage.getItem("city");

    if (cityItem) this.setState({ city: cityItem });

    this.props.onFetchAdvertisements("/imoveis-a-venda");

    const searchParams = {
      cidade: cityItem,
      post_type: "empreendimento",
      created_from: "xml" 
    };

    if (this.state.specificPartners) {
      searchParams.created_from = "";
    }

    this.onSearch(searchParams);

    this.loadFilter(cityItem);
  };

  itsEmpty = () => {
    this.setState({ postsSelected: [] });
  };

  loadFilter = (city) => {
    const properties = city ? `?cidade=${city}` : "";
    api
      .get("/posts/filter" + properties)
      .then((res) => {
        this.setState({ filterOptions: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleMapVision = () => {
    if (this.state.mapVision) {
      this.setState({
        mapVision: false,
      });
    } else {
      this.setState({
        mapVision: true,
      });
    }
  };

  getImobiliariaPosts = (city) => {
    api
      .post("/posts/imobiliaria/posts", {
        city: city,
      })
      .then((res) => {
        console.log(res);
        this.setState({ forSale: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getFilteredPosts = (parameters) => {
    api
      .get("/posts/search/" + parameters)
      .then((res) => {
        this.setState({ forSale: res.data.posts, count: res.data.count });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangeCity = (e) => {
    const city = e.target.value;
    this.setState({
      city: city,
    });
    this.loadFilter(city);
  };

  onSearch = (filterData, page = this.state.page) => {
    const keyObj = { ...filterData, page: page };
  
    const { id } = this.props.match.params;
  
    if (id) {
      if (window.location.pathname.includes("construtora")) {
        keyObj.construtora = id;
      } else if (window.location.pathname.includes("administradora")) {
        keyObj.administradora = id;
      } else {
        keyObj.imobiliaria = id;
      }
    }
  
    var parameters = "?";
    const keyLen = Object.keys(keyObj).length;
    Object.keys(keyObj).forEach((key, idx) => {
      if (keyObj[key]) {
        parameters += `${key}=${keyObj[key]}`;
        if (idx + 1 !== keyLen) {
          parameters += "&";
        }
      }
    });
  
    if (filterData.cidade)
      window.localStorage.setItem("city", filterData.cidade);
  
    this.setState({
      city: filterData.cidade,
      neighborhood: filterData.bairro,
      postsSelected: [],
      forSale: [],
      filterData: filterData,
    });
  
    this.props.onFetchReleases("/lancamentos", filterData.cidade);
    this.getFilteredPosts(parameters);
  };
  handlePageChange = (event, page) => {
    this.setState({ page: page });
    this.onSearch(this.state.filterData, page);
  };

  buildURL = ({ loc = "", value = this.state.value }) => {
    let url = `/posts/search?created_from=xml&location=${loc}`;
    if (value) url = url + "&post_title=" + value;
    return url;
  };

  find = (payload = {}) => {
    const url = this.buildURL(payload);
    api
      .get(url)
      .then((res) => {
        this.setState({
          forSale: res.data.posts,
        });
      })
      .catch((err) => {});
  };

  onLoadPolygonCoordinates = (coordenadas, location) => {
    const [lngne, latne, lngsw, latsw] = location;
    if (lngne && latne && lngsw && latsw) {
      const location = [lngne, latne, lngsw, latsw].join(",");
      this.find({ loc: location });
      this.props.onLoadSuccess({
        lngne: lngne,
        latne: latne,
        lngsw: lngsw,
        latsw: latsw,
        coordenadas: coordenadas,
      });
    }
  };

  render() {
    const filterSearch = this.state.specificPartners ? undefined : (
      <FilterSearch
        id={this.props.match.params.id}
        city={this.state.city}
        neighborhood={this.state.neighborhood}
        onChangeCity={this.onChangeCity}
        onSearch={this.onSearch}
        filterOptions={this.state.filterOptions}
      />
    );

    const selectVision = (
      <FormControlLabel
        style={{ display: "flex", justifyContent: "center" }}
        control={
          <Switch
            checked={this.state.mapVision}
            onChange={this.handleMapVision}
            color="primary"
          />
        }
        label="Visão Mapa"
      />
    );

    var mapVision = this.state.mapVision && (
      <BuscaMapaImoveis
        markerColor={markerColor}
        imoveisWithinPolygon={this.state.forSale}
        onLoadPolygonCoordinates={this.onLoadPolygonCoordinates}
      />
    );

    return (
      <>
        <Helmet>
          {this.state.specificPartners ? (
            <title> Imóveis </title>
          ) : (
            <title> Imóveis à venda — Plano Cidade</title>
          )}
        </Helmet>
        {this.props.posts && (
          <Partners
            title={"Imóveis"}
            citySearch={filterSearch}
            posts={this.state.forSale}
            postSelected={this.state.postsSelected}
            advertisements={this.props.advertisements}
            count={this.state.count}
            page={this.state.page}
            handlePageChange={this.handlePageChange}
            pagination
            mapVision={mapVision}
            selectVision={selectVision}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    posts: state.imoveis.posts,
    advertisements: state.advertise.advertisements,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchReleases: (page, city) =>
      dispatch(actions.fetchReleases(page, city)),
    onFetchAdvertisements: (page) =>
      dispatch(actions.fetchAdvertisements(page)),
    onLoadSuccess: (payload) => dispatch(actions.loadSuccess(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImoveisAVenda);
