export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS';
export const RECOVER_PASS_SUCCESS = 'RECOVER_PASS_SUCCESS';
export const SHOW_ERRORS = 'SHOW_ERRORS';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const CHANGE_USER = 'CHANGE_USER';
export const DELETE_USER_START ='DELETE_USER_START';
export const DELETE_USER_SUCCESS ='DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL ='DELETE_USER_FAIL';
export const UPDATE_USER_START ='UPDATE_USER_START';
export const UPDATE_USER_SUCCESS ='UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL ='UPDATE_USER_FAIL';
export const DELETE_AVATAR_START ='DELETE_AVATAR_START';
export const DELETE_AVATAR_FAIL ='DELETE_AVATAR_FAIL';
export const DELETE_AVATAR_SUCCESS ='DELETE_AVATAR_SUCCESS';

export const CHANGE_ASSINATURA = 'CHANGE_ASSINATURA';
export const SALVAR_CARTAO_START ='SALVAR_CARTAO_START';
export const SALVAR_CARTAO_SUCCESS ='SALVAR_CARTAO_SUCCESS';
export const SALVAR_CARTAO_FAIL ='SALVAR_CARTAO_FAIL';
export const CANCELAR_ASSINATURA_START ='CANCELAR_ASSINATURA_START';
export const CANCELAR_ASSINATURA_SUCCESS ='CANCELAR_ASSINATURA_SUCCESS';
export const CANCELAR_ASSINATURA_FAIL ='CANCELAR_ASSINATURA_FAIL';
export const REATIVAR_ASSINATURA_START ='REATIVAR_ASSINATURA_START';
export const REATIVAR_ASSINATURA_SUCCESS ='REATIVAR_ASSINATURA_SUCCESS';
export const REATIVAR_ASSINATURA_FAIL ='REATIVAR_ASSINATURA_FAIL';
export const LOAD_ASSINATURA_START ='LOAD_ASSINATURA_START';
export const LOAD_ASSINATURA_SUCCESS ='LOAD_ASSINATURA_SUCCESS';
export const LOAD_ASSINATURA_FAIL ='LOAD_ASSINATURA_FAIL';

export const FETCH_FAVORITOS_START = 'FETCH_FAVORITOS_START';
export const FETCH_FAVORITOS_SUCCESS = 'FETCH_FAVORITOS_SUCCESS';
export const FETCH_FAVORITOS_ALL_SUCCESS = 'FETCH_FAVORITOS_ALL_SUCCESS';
export const FILTER_FAVORITOS = 'FILTER_FAVORITOS';
export const FETCH_FAVORITOS_FAIL = 'FETCH_FAVORITOS_FAIL';
export const SALVAR_FAVORITO_START = 'SALVAR_FAVORITO_START';
export const SALVAR_FAVORITO_SUCCESS = 'SALVAR_FAVORITO_SUCCESS';
export const SALVAR_FAVORITO_FAIL = 'SALVAR_FAVORITO_FAIL';
export const CRIAR_FAVORITO_START = 'CRIAR_FAVORITO_START';
export const CRIAR_FAVORITO_SUCCESS = 'CRIAR_FAVORITO_SUCCESS';
export const CRIAR_FAVORITO_FAIL = 'CRIAR_FAVORITO_FAIL';
export const DELETE_FAVORITO_START = 'DELETE_FAVORITO_START';
export const DELETE_FAVORITO_SUCCESS = 'DELETE_FAVORITO_SUCCESS';
export const DELETE_FAVORITO_FAIL = 'DELETE_FAVORITO_FAIL';
export const DELETE_IMAGE_FAVORITO_START = 'DELETE_IMAGE_FAVORITO_START';
export const DELETE_IMAGE_FAVORITO_SUCCESS = 'DELETE_IMAGE_FAVORITO_SUCCESS';
export const DELETE_IMAGE_FAVORITO_FAIL = 'DELETE_IMAGE_FAVORITO_FAIL';
export const EXPORT_FAVORITO_START = 'EXPORT_FAVORITO_START';
export const EXPORT_FAVORITO_SUCCESS = 'EXPORT_FAVORITO_SUCCESS';
export const EXPORT_FAVORITO_FAIL = 'EXPORT_FAVORITO_FAIL';
export const SET_FAVORITO = 'SET_FAVORITO';
export const LOAD_FAVORITO = 'LOAD_FAVORITO';
export const CHANGE_FAVORITO = 'CHANGE_FAVORITO';
export const CHANGE_INTERESSADO = 'CHANGE_INTERESSADO';
export const ADD_INTERESSADO = 'ADD_INTERESSADO';
export const REMOVE_INTERESSADO = 'REMOVE_INTERESSADO';

export const LOAD_SUCCESS = 'LOAD_SUCCESS';
export const LOAD_FAIL = 'LOAD_FAIL';
export const LOAD_LOCALIZACAO_START = 'LOAD_LOCALIZACAO_START';
export const LOAD_LOCALIZACAO_SUCCESS = 'LOAD_LOCALIZACAO_SUCCESS';
export const LOAD_LOCALIZACAO_FAIL = 'LOAD_LOCALIZACAO_FAIL';
export const MAP_CENTER = 'MAP_CENTER';
export const SET_CENTER = 'SET_CENTER';
export const SET_BUSCOU = 'SET_BUSCOU';
export const SET_FIRST_TIME = 'SET_FIRST_TIME';
export const SET_LOCALIZACAO_SUCCESS = 'SET_LOCALIZACAO_SUCCESS';
export const ERROR_GET_LOCALIZACAO = 'ERROR_GET_LOCALIZACAO';
export const INIT_MAPA_START = 'INIT_MAPA_START';
export const INIT_MAPA_SUCCESS = 'INIT_MAPA_SUCCESS';
export const INIT_MAPA_FAIL = 'INIT_MAPA_FAIL';
export const SET_FAVORITO_HOME = 'SET_FAVORITO_HOME';

export const MAP_CENTER_FAV = 'MAP_CENTER_FAV';
export const SET_CENTER_FAV = 'SET_CENTER_FAV';
export const SET_LOCALIZACAO_SUCCESS_FAV = 'SET_LOCALIZACAO_SUCCESS_FAV';
export const ERROR_GET_LOCALIZACAO_FAV = 'ERROR_GET_LOCALIZACAO_FAV';
export const INIT_MAPA_START_FAV = 'INIT_MAPA_START_FAV';
export const INIT_MAPA_SUCCESS_FAV = 'INIT_MAPA_SUCCESS_FAV';
export const INIT_MAPA_FAIL_FAV = 'INIT_MAPA_FAIL_FAV';

export const SET_LOCALIZACAO = 'SET_LOCALIZACAO';

export const SEND_ANNOUNCEMENT_MESSAGE_START = 'SEND_ANNOUNCEMENT_MESSAGE_START';
export const SEND_ANNOUNCEMENT_MESSAGE_SUCCESS = 'SEND_ANNOUNCEMENT_MESSAGE_SUCCESS';
export const SEND_ANNOUNCEMENT_MESSAGE_FAIL = 'SEND_ANNOUNCEMENT_MESSAGE_FAIL';
export const SEND_CLIENT_MESSAGE_START = 'SEND_ANNOUNCEMENT_MESSAGE_START';
export const SEND_CLIENT_MESSAGE_SUCCESS = 'SEND_CLIENT_MESSAGE_SUCCESS';
export const SEND_CLIENT_MESSAGE_FAILURE = 'SEND_CLIENT_MESSAGE_FAILURE';

export const FETCH_POSTS_START = 'FETCH_POSTS_START';
export const FETCH_POSTS_FAIL = 'FETCH_POSTS_FAIL';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';

export const FETCH_ANNOUNCEMENT_MESSAGES_START = 'FETCH_ANNOUNCEMENT_MESSAGES_START';
export const FETCH_ANNOUNCEMENT_MESSAGES_FAIL = 'FETCH_ANNOUNCEMENT_MESSAGES_FAIL';
export const FETCH_ANNOUNCEMENT_MESSAGES_SUCCESS = 'FETCH_ANNOUNCEMENT_MESSAGES_SUCCESS';

export const FETCH_ANNOUNCEMENT_MESSAGE_START = 'FETCH_ANNOUNCEMENT_MESSAGE_START';
export const FETCH_ANNOUNCEMENT_MESSAGE_FAIL = 'FETCH_ANNOUNCEMENT_MESSAGE_FAIL';
export const FETCH_ANNOUNCEMENT_MESSAGE_SUCCESS = 'FETCH_ANNOUNCEMENT_MESSAGE_SUCCESS';

export const FETCH_POST_START = 'FETCH_POST_START';
export const FETCH_POST_FAIL = 'FETCH_POST_FAIL';
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';

export const UPDATE_POST = 'UPDATE_POST';
export const UPDATE_BASIC_POST = 'UPDATE_BASIC_POST';

export const SAVE_POST_START = 'SAVE_POST_START';
export const SAVE_POST_FAIL = 'SAVE_POST_FAIL';
export const SAVE_POST_SUCCESS = 'SAVE_POST_SUCCESS';

export const DELETE_POST_START = 'DELETE_POST_START';
export const DELETE_POST_FAIL = 'DELETE_POST_FAIL';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';

export const FETCH_REPORT_START = 'FETCH_REPORT_START';
export const FETCH_REPORT_FAIL = 'FETCH_REPORT_FAIL';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';

export const FETCH_REPORTS_START = 'FETCH_REPORTS_START';
export const FETCH_REPORTS_FAIL = 'FETCH_REPORTS_FAIL';
export const FETCH_REPORTS_SUCCESS = 'FETCH_REPORTS_SUCCESS';

export const SEND_ADVERTISE_START = 'SEND_ADVERTISE_START';
export const SEND_ADVERTISE_SUCCESS = 'SEND_ADVERTISE_SUCCESS';
export const SEND_ADVERTISE_FAIL = 'SEND_ADVERTISE_FAIL';

export const FETCH_ADVERTISES_START = 'FETCH_ADVERTISES_START';
export const FETCH_ADVERTISES_FAIL = 'FETCH_ADVERTISES_FAIL';
export const FETCH_ADVERTISES_SUCCESS = 'FETCH_ADVERTISES_SUCCESS';

export const FETCH_ADVERTISE_START = 'FETCH_ADVERTISE_START';
export const FETCH_ADVERTISE_FAIL = 'FETCH_ADVERTISE_FAIL';
export const FETCH_ADVERTISE_SUCCESS = 'FETCH_ADVERTISE_SUCCESS';

export const FETCH_ADVERTISEMENTS_START = 'FETCH_ADVERTISEMENTS_START';
export const FETCH_ADVERTISEMENTS_SUCCESS = 'FETCH_ADVERTISEMENTS_SUCCESS';
export const FETCH_ADVERTISEMENTS_FAIL = 'FETCH_ADVERTISEMENTS_FAIL';

export const UPDATE_ADVERTISE = 'UPDATE_ADVERTISE';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_ADMINS_START = 'FETCH_ADMINS_START';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_FAIL = 'FETCH_ADMINS_FAIL';

export const FETCH_USER_BY_ADMIN_START = 'FETCH_USER_BY_ADMIN_START';
export const FETCH_USER_BY_ADMIN_SUCCESS = 'FETCH_USER_BY_ADMIN_SUCCESS';
export const FETCH_USER_BY_ADMIN_FAIL = 'FETCH_USER_BY_ADMIN_FAIL';

export const RESET_USERS_PROPS = 'RESET_USERS_PROPS';
