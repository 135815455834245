import React from 'react';
import Grid from '@material-ui/core/Grid';
import Footer from "../UI/Footer";
import InformationCard from "../UI/InformationCard";
import ImageDefault from '../../assets/images/sample-image.jpg';
import Pagination from "@material-ui/lab/Pagination";

// import AdvertisementImages from "../AdvertisementImages/AdvertisementImages";
import { serveImage } from '../../utils/serveImage';

export default function Partners ({ title, subtitle, pathurl, posts, partnerUrl, search, mapVision, selectVision, citySearch, postSelected, advertisements, count, page, handlePageChange, pagination = false }) {
  const getImageSrc = (p) => (
    (p.metas && p.metas.thumbnail && serveImage(p.metas.thumbnail.url))
    || (p.metas && p.metas.banner_principal && serveImage(p.metas.banner_principal.url))
    || (p.metas && p.metas.fotos && p.metas.fotos.length && serveImage(p.metas.fotos[0].url))
    || ImageDefault
  )

  return (
    <>
      <br />
      {/* <AdvertisementImages
        advertisements={advertisements['top']}
        width='728px'
        height='90px'
      /> */}
      <br />
      <div style={{ backgroundColor: '#ededed', padding: '30px' }}>
        {
          partnerUrl ?
            <a href={partnerUrl} target='_blank' rel='noopener noreferrer' style={{ display: 'flex', justifyContent: 'center', color: '#687700', fontSize: '30px' }}>{title && title}</a>
            : <span style={{ display: 'flex', justifyContent: 'center', color: '#687700', fontSize: '30px' }}>{title && title}</span>
        }
        <span style={{ display: 'flex', justifyContent: 'center', color: '#aac401', fontSize: '30px' }}>{subtitle && subtitle}</span><br />
        { citySearch && citySearch }
        { selectVision && selectVision }
        { search && search }
        { mapVision || (
          <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Grid container spacing={5} style={{ maxWidth: '1200px' }}>
              { 
                (postSelected && postSelected.length > 0) ? (
                  postSelected.map(p => (
                    <InformationCard
                      id={p._id}
                      post_title={p.post_title}
                      tipo={p.metas.tipo}
                      area={p.metas.area}
                      valor_inicial={p.metas.valor_inicial}
                      valor_do_condomnio={p.metas.valor_do_condomnio}
                      bairro={p.metas.bairro}
                      cidade={p.metas.cidade}
                      pathurl={pathurl}
                      src={getImageSrc(p)}
                      emailProprietary={p.metas.email_do_contato}
                      numberPhone={p.metas.celular_de_contato}
                    />
                  ))
                ) : (
                  (posts && posts.length > 0) && posts.map(p => (
                    <InformationCard
                      id={p._id}
                      post_title={p.post_title}
                      tipo={p.metas.tipo}
                      area={p.metas.area}
                      valor_inicial={p.metas.valor_inicial}
                      valor_do_condomnio={p.metas.valor_do_condomnio}
                      bairro={p.metas.bairro}
                      cidade={p.metas.cidade}
                      pathurl={pathurl}
                      src={getImageSrc(p)}
                      emailProprietary={p.metas.email_do_contato}
                      numberPhone={p.metas.celular_de_contato}
                      created_from={p.created_from !== undefined ? p.created_from : ''}
                    />
                  ))
                )
              }
            </Grid>
          </div>
        )}
      </div>
      {  pagination && !mapVision && <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Pagination
          count={count}
          page={page}
          onChange={handlePageChange}
          shape="rounded"
        />
      </div>}
      <br />
      {/* <AdvertisementImages
        advertisements={advertisements['bottom']}
        width='728px'
        height='300px'
      /> */}
      <br />
      <Footer />
    </>
  )
}
